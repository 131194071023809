import Carousel from "stimulus-carousel"

export default class extends Carousel {
  static targets = [ "image", "images" ]
  connect() {
    this.mainImgIndex = 0
  }

  select(event) {
    const image = event.target
    this.mainImgIndex = [...this.imagesTargets].indexOf(image)
    this.updateImgs()
  }

  updateImgs(){
    this.imagesTargets.forEach(img => {
      img.classList.remove("active")
    });
    const selectedImg = this.imagesTargets[this.mainImgIndex];
    selectedImg.classList.add("active")
    this.imageTarget.src = selectedImg.src
    selectedImg.parentNode.scrollTo({ left: selectedImg.offsetLeft - selectedImg.width, behavior: 'smooth' });
  }

  next() {
    this.mainImgIndex += 1
    this.updateImgs()
  }

  previous() {
    this.mainImgIndex -= 1
    this.updateImgs()
  }
}
